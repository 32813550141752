// Built-in modules
import qs from "qs";

// Third-party libraries
import React, { useEffect, useState, useRef } from "react";
import { Link as DomLink } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

import {
  Container,
  Card,
  CardContent,
  Typography,
  Stack,
  Backdrop,
  CircularProgress,
  Grid,
} from "@mui/material";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import VolumeMuteIcon from "@mui/icons-material/VolumeMute";
import useMediaQuery from "@mui/material/useMediaQuery";

// Local modules
import api from "../../../utils/api";
import { constants } from "../../../utils";
import LandingFooter from "./LandingFooter";
import ButtonTextBlock from "./styles/ButtonTextBlock";
import LeftCopyBox from "./styles/LeftCopyBox";
import RightCopyBox from "./styles/RightCopyBox";
import ThreeImagesCard from "./styles/ThreeImagesCard";

export default function StaticLanding(props) {
  const videoRef = useRef(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [banner, setBanner] = useState();
  const [topgreen, setTopGreen] = useState();
  const [textblock, setTextBlock] = useState();
  const [threecards, setThreeCards] = useState();
  const [threeimages, setThreeImages] = useState();
  const [buttontextblock1, setButtonTextBlock1] = useState();
  const [imagecopyboxes, setImageCopyBoxes] = useState();
  const [buttontextblock2, setButtonTextBlock2] = useState();
  const [bottomgreen, setBottomGreen] = useState();
  const [icons, setIcons] = useState();
  const [isMuted, setIsMuted] = useState(false);

  const frontImageUrl = constants.CMS_FRONT_IMAGE_URL;
  const isSmScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const page =
    props.match.params.PageType === "Harmony"
      ? constants.HARMONY_ID
      : props.match.params.PageType === "Refining"
      ? constants.REFINING_ID
      : props.match.params.PageType === "Contract-Casting"
      ? constants.CONTRACT_CASTING_ID
      : props.match.params.PageType === "Findings"
      ? constants.FINDINGS_ID
      : props.match.params.PageType === "Bands"
      ? constants.BANDS_ID
      : props.match.params.PageType === "Bridal-Jewelry"
      ? constants.BRIDAL_ID
      : props.match.params.PageType === "Finished-Jewelry"
      ? constants.FINISHED_JEWELRY_ID
      : props.match.params.PageType === "Diamonds"
      ? constants.DIAMONDS_ID
      : props.match.params.PageType === "Refining-For-Dentists"
      ? constants.REFINING_FOR_DENTISTS
      : props.match.params.PageType === "Refining-For-Private-Individuals"
      ? constants.REFINING_FOR_PRIVATE_INDIVIDUALS
      : props.match.params.PageType === "Mill-Products"
      ? constants.MILL
      : 1;

  const toggleMute = () => {
    const video = videoRef.current;
    video.muted = !video.muted;
    setIsMuted(video.muted);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    const query = qs.stringify({
      populate: {
        banner: {
          populate: "*",
        },
        topgreen: {
          populate: "*",
        },
        textblock: {
          populate: "*",
        },
        threecards: {
          populate: "*",
        },
        threeimages: {
          populate: "*",
        },
        buttontextblock1: {
          populate: "*",
        },
        imagecopyboxes: {
          populate: "*",
        },
        buttontextblock2: {
          populate: "*",
        },
        bottomgreen: {
          populate: "*",
        },
        icons: {
          populate: "*",
        },
      },
    });
    api.fetchStrapi(`/landing-pages/${page}?${query}`).then((response) => {
      setBanner(
        response.data.data.attributes.banner.data
          ? response.data.data.attributes.banner.data.attributes
          : null
      );
      setTopGreen(response.data.data.attributes.topgreen);
      setTextBlock(response.data.data.attributes.textblock);
      setThreeCards(response.data.data.attributes.threecards);
      setThreeImages(response.data.data.attributes.threeimages);
      setButtonTextBlock1(response.data.data.attributes.buttontextblock1);
      setImageCopyBoxes(response.data.data.attributes.imagecopyboxes);
      setButtonTextBlock2(response.data.data.attributes.buttontextblock2);
      setBottomGreen(response.data.data.attributes.bottomgreen);
      setIcons(response.data.data.attributes.icons);
      setIsLoaded(true);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.match.params.PageType]);

  return (
    <>
      {!isLoaded ? (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <Container maxWidth="xl" className="careers-text">
          <Stack spacing={3} alignItems="center">
            {banner &&
              (banner.mime.startsWith("image/") ? (
                <img
                  src={`${frontImageUrl}${banner.url}`}
                  width="100%"
                  alt="banner"
                />
              ) : (
                <div
                  style={{
                    position: "relative",
                    display: "inline-block",
                  }}
                >
                  <video
                    ref={videoRef}
                    width="100%"
                    autoPlay
                    loop
                    muted={isMuted}
                    src={`${frontImageUrl}${banner.url}`}
                  />
                  <button onClick={toggleMute} className="mute-button">
                    {isMuted ? (
                      <VolumeMuteIcon style={{ fontSize: 40 }} />
                    ) : (
                      <VolumeUpIcon style={{ fontSize: 40 }} />
                    )}
                  </button>
                </div>
              ))}
            {topgreen && (
              <Typography variant="greenRectangle" align="center">
                {topgreen.text}
              </Typography>
            )}
            {threecards && threecards.length > 0 && (
              <Stack direction="row" spacing={4}>
                {threecards.map((card) => (
                  <DomLink
                    to={{
                      pathname: `${card.link}`,
                    }}
                  >
                    <Card variant="outlined" sx={{ width: "25%" }}>
                      <img
                        src={`${frontImageUrl}${card.icon.data.attributes.url}`}
                        alt="left"
                        style={{ paddingTop: "2rem" }}
                      />
                      <CardContent>
                        <Typography variant="staticText">
                          <ReactMarkdown remarkPlugins={[remarkGfm]}>
                            {card.link}
                          </ReactMarkdown>
                        </Typography>
                      </CardContent>
                    </Card>
                  </DomLink>
                ))}
              </Stack>
            )}
            {threeimages.map((threeimages) => (
              <Grid
                container
                direction={isSmScreen ? "column" : "row"}
                spacing={2}
                key={threeimages.id}
              >
                <Grid item xs={12} sm={6} md={4}>
                  {ThreeImagesCard(
                    threeimages.image1.data.attributes,
                    threeimages.image1pdf
                  )}
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  {ThreeImagesCard(
                    threeimages.image2.data.attributes,
                    threeimages.image2pdf
                  )}
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  {ThreeImagesCard(
                    threeimages.image3.data.attributes,
                    threeimages.image3pdf
                  )}
                </Grid>
              </Grid>
            ))}
            {textblock && (
              <Stack
              // sx={
              //   textblock.background === "Blue"
              //     ? {
              //         backgroundColor: "#cfdde9",
              //         width: "100%",
              //         paddingBottom: "2rem",
              //       }
              //     : {
              //         backgroundColor: "inherit",
              //         width: "100%",
              //         paddingBottom: "2rem",
              //       }
              // }
              >
                {/* <Typography
                  variant="articleTitle"
                  align="center"
                  sx={{ paddingTop: "2rem" }}
                >
                  {textblock.title}
                </Typography> */}
                <Typography
                  variant="staticText"
                  align="center"
                  sx={{
                    paddingLeft: "6rem",
                    paddingRight: "6rem",
                    wordSpacing: "2rem",
                  }}
                >
                  <ReactMarkdown remarkPlugins={[remarkGfm]}>
                    {textblock.body}
                  </ReactMarkdown>
                </Typography>
              </Stack>
            )}
            {buttontextblock1 ? ButtonTextBlock(buttontextblock1) : ""}
            {imagecopyboxes.map((copyBox) => (
              <React.Fragment key={copyBox.id}>
                {copyBox.alignment === "left"
                  ? LeftCopyBox(copyBox)
                  : RightCopyBox(copyBox)}
              </React.Fragment>
            ))}
            {ButtonTextBlock(buttontextblock2)}
            <Typography variant="greenRectangle" align="center">
              {bottomgreen.text}
            </Typography>
            <LandingFooter icons={icons} />
          </Stack>
        </Container>
      )}
    </>
  );
}
